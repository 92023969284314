import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/render/project/src/src/components/MdxPageLayout/index.jsx";
import ComparisonResultsPageHero from "../../components/ComparisonResultsPageHero";
import ComparisonResultPageTextWithImageSection from "../../components/ComparisonResultPageTextWithImageSection";
import ComparisonResultHeroUserLocation from "../../components/ComparisonResultHeroUserLocation";
import ComparisonResultGrid from "../../components/ComparisonResultGrid";
import ComparisonResultColumnContent from "../../components/ComparisonResultColumnContent";
import ComparisonResultColumnContentBackgroundContainer from "../../components/ComparisonResultColumnContentBackgroundContainer";
import ComparisonResultGettingStartedBlock from "../../components/ComarisonResultPageGetStartedBlock";
import ComparisonPageRecommendations from "../../components/ComparisonPageRecommendations";
import ComparisonResultPageLearnMoreContainer from "../../components/ComparisonResultPageLearnMoreContainer";
import StudentLoanIllustration from "../../../svg/comparison-illustrations/studentrefinancingloan.svg";
import SplashSVG from "../../../svg/ComparisonResultGridLogos/studentloans/SplashFinancial.svg";
import SoFiSVG from "../../../svg/ComparisonResultGridLogos/studentloans/sofi.svg";
import CitizensSVG from "../../../svg/ComparisonResultGridLogos/studentloans/citizens.svg";
import EarnestSVG from "../../../svg/ComparisonResultGridLogos/studentloans/Earnest.svg";
import MefaSVG from "../../../svg/ComparisonResultGridLogos/studentloans/mefa.svg";
export const pageMetaData = {
  pageTitle: "Compare Student Refinancing Loans",
  pageDescription: "Variable APR From 1.99%",
  pageImagePath: "/studentrefinancingloans.jpg",
  showNavbar: false,
  pageMetaTags: []
};
export const resultGridConfig = {
  columnConfiguration: {
    columns: [{
      index: 1,
      rows: [{
        index: 1,
        text: "Loan Amount"
      }, {
        index: 2,
        text: "Suitable For"
      }, {
        index: 3,
        text: "Loan Term"
      }, {
        index: 4,
        text: "APR % From"
      }, // {
      //   index: 5,
      //   text: "Fixed APR %",
      // },
      {
        index: 6,
        text: "Minimum Credit Score"
      }],
      expansionRows: []
    }, {
      index: 2,
      isComingSoon: false,
      rows: [{
        index: 1,
        isHeadingCell: true,
        displayHeadingCell: true,
        heading: {
          text: "Splash Financial",
          subText: "",
          imageKey: "splash"
        }
      }, {
        index: 2,
        text: "5000 +",
        isPriceCell: true,
        showSavingText: false,
        savingText: "Save From $3,000",
        disableSavingStrikeThrough: true,
        showRating: true,
        rating: 5
      }, {
        index: 3,
        text: "Graduates"
      }, {
        index: 4,
        text: "5 - 20 Years"
      }, {
        index: 5,
        text: "From 1.89%"
      }, // {
      //   index: 6,
      //   text: "From 2.88%",
      // },
      {
        index: 7,
        text: "660 +"
      }],
      expansionRows: [],
      claimButtonConfiguration: {
        targetUrl: "https://splash-financial.j48ltb.net/c/1323808/583350/9516",
        openLinkExternally: true,
        text: "Find Out More",
        title: "Click to sign up to Splash Financial",
        subText: "",
        showIcon: false
      }
    }, {
      index: 3,
      isComingSoon: false,
      rows: [{
        index: 1,
        isHeadingCell: true,
        displayHeadingCell: true,
        heading: {
          text: "Citizens",
          subText: "",
          imageKey: "citizen"
        }
      }, {
        index: 2,
        text: "10,000 +",
        isPriceCell: true,
        showSavingText: false,
        savingText: "Save From $3,000",
        disableSavingStrikeThrough: true,
        showRating: true,
        rating: 4
      }, {
        index: 3,
        text: "Graduates"
      }, {
        index: 4,
        text: "5 - 20 Years"
      }, {
        index: 5,
        text: "From 2.97%"
      }, // {
      //   index: 6,
      //   text: "from 3.75%",
      // },
      {
        index: 7,
        text: "680 +"
      }],
      expansionRows: [],
      claimButtonConfiguration: {
        targetUrl: "https://www.credible.com/a/dd-citizens/?utm_source=comparisoncompany&utm_medium=referral&utm_campaign=citizens_slr&utm_content=",
        openLinkExternally: true,
        text: "Find Out More",
        subText: "",
        showIcon: false
      }
    }, {
      index: 4,
      isComingSoon: false,
      rows: [{
        index: 1,
        isHeadingCell: true,
        displayHeadingCell: true,
        heading: {
          text: "Earnest",
          subText: "",
          imageKey: "earnest"
        }
      }, {
        index: 2,
        text: "5,000 +",
        isPriceCell: true,
        showSavingText: false,
        savingText: "Save From $3,000",
        disableSavingStrikeThrough: true,
        showRating: true,
        rating: 3
      }, {
        index: 3,
        text: "Debt Consolidation"
      }, {
        index: 4,
        text: "Upto 6 Years"
      }, {
        index: 5,
        text: "From 8.99%"
      }, // {
      //   index: 6,
      //   text: "From 3.19%",
      // },
      {
        index: 7,
        text: "650+"
      }],
      expansionRows: [],
      claimButtonConfiguration: {
        targetUrl: "https://earnest.qylf.net/c/1437495/615605/9109?sharedid=The%20Comparison%20Company",
        openLinkExternally: true,
        text: "Find Out More",
        subText: "",
        showIcon: false
      }
    }, {
      index: 5,
      isComingSoon: false,
      rows: [{
        index: 1,
        isHeadingCell: true,
        displayHeadingCell: true,
        heading: {
          text: "Sofi",
          subText: "",
          imageKey: "sofi"
        }
      }, {
        index: 2,
        text: "5,000 +",
        isPriceCell: true,
        showSavingText: false,
        savingText: "Save From $3,000",
        disableSavingStrikeThrough: true,
        showRating: true,
        rating: 4
      }, {
        index: 3,
        text: "Graduates"
      }, {
        index: 4,
        text: "5 - 20 Years"
      }, {
        index: 5,
        text: "From 2.99%"
      }, // {
      //   index: 6,
      //   text: "From 3.19%",
      // },
      {
        index: 7,
        text: "700+"
      }],
      expansionRows: [],
      claimButtonConfiguration: {
        targetUrl: "https://www.credible.com/a/dd-sofi/?utm_source=comparisoncompany&utm_medium=referral&utm_campaign=sofi_slr&utm_content=",
        openLinkExternally: true,
        text: "Find Out More",
        subText: "",
        showIcon: false
      }
    }, {
      index: 5,
      isComingSoon: false,
      rows: [{
        index: 1,
        isHeadingCell: true,
        displayHeadingCell: true,
        heading: {
          text: "Mefa",
          subText: "",
          imageKey: "mefa"
        }
      }, {
        index: 2,
        text: "10,000 +",
        isPriceCell: true,
        showSavingText: false,
        savingText: "Save From $3,000",
        disableSavingStrikeThrough: true,
        showRating: true,
        rating: 4
      }, {
        index: 3,
        text: "Graduates"
      }, {
        index: 4,
        text: "7 - 15 Years"
      }, {
        index: 5,
        text: "From 3.05%"
      }, // {
      //   index: 6,
      //   text: "From 3.19%",
      // },
      {
        index: 7,
        text: "670+"
      }],
      expansionRows: [],
      claimButtonConfiguration: {
        targetUrl: "https://www.credible.com/a/dd-mefa-slr/?utm_source=comparisoncompany&utm_medium=referral&utm_campaign=mefa_slr&utm_content=",
        openLinkExternally: true,
        text: "Find Out More",
        subText: "",
        showIcon: false
      }
    }]
  }
};
export const _frontmatter = {};
const layoutProps = {
  pageMetaData,
  resultGridConfig,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <ComparisonResultsPageHero mdxType="ComparisonResultsPageHero">
      <h1>{`Compare student loan refinancing`}</h1>
      <ComparisonResultPageTextWithImageSection images={[<StudentLoanIllustration imagekey="large-view" className="h-56 ml-auto" mdxType="StudentLoanIllustration" />, <StudentLoanIllustration imagekey="mobile-view" className="h-56 w-full" mdxType="StudentLoanIllustration" />]} mdxType="ComparisonResultPageTextWithImageSection">
        <p>{`The state of student loans in America is nothing less than a crisis. With over 1.5 trillion dollars in student loan debt and at least 42% of millennials worried about paying back student loans they owe, it's easy to see that college graduates and college students all over the country are struggling with how to pay these loans back. Even more concerning, student loan debt is not only for college graduates - roughly 9% of people who only have high school degrees also have student debt.`}</p>
        <p>{`Fortunately, some great companies are committed to providing college students and graduates with financial relief. We did the work for you to compare loans for the best student loan refinancing companies.`}</p>
      </ComparisonResultPageTextWithImageSection>
      <ComparisonResultHeroUserLocation productCategory="Student Refinancing Loans" mdxType="ComparisonResultHeroUserLocation" />
    </ComparisonResultsPageHero>
    <ComparisonResultGrid columnConfiguration={resultGridConfig.columnConfiguration} mdxType="ComparisonResultGrid">
  <SplashSVG className="w-full" imagekey="splash" mdxType="SplashSVG" />
  <SoFiSVG className="w-full" imagekey="sofi" mdxType="SoFiSVG" />
  <CitizensSVG className="w-full" imagekey="citizen" mdxType="CitizensSVG" />
  <EarnestSVG className="w-full" imagekey="earnest" mdxType="EarnestSVG" />
  <MefaSVG className="w-full" imagekey="mefa" mdxType="MefaSVG" />
    </ComparisonResultGrid>
    <ComparisonResultColumnContent blogContentCategory="loans" mdxType="ComparisonResultColumnContent">
      <ComparisonResultColumnContentBackgroundContainer mdxType="ComparisonResultColumnContentBackgroundContainer">
        <h2>{`How We Ranked Student Loan Providers`}</h2>
        <p>{`When it’s time to look at companies providing student loan relief to college students, we used many qualifications to determine which providers are the best of the best.`}</p>
        <h3>{`Region`}</h3>
        <p>{`One of the first qualifiers we looked at was whether these providers serviced students exclusively and if any of these companies were committed to helping students with the cost of in-state tuition. The majority of providers on this list focus on assisting residents to become successful college students at state schools.`}</p>
        <h3>{`Type of Loan`}</h3>
        <p>{`We also looked at what kind of loans the provider worked with. To pay for college, schools accept private or federal loans to cover the cost of tuition and sometimes housing and other school expenses, such as textbooks and graduate requirements. While many of these companies work with both private and federal loans, several others serve either one or the other. It’s important to know the kind of loan you need and qualify for when searching for loan providers.`}</p>
        <h3>{`Consolidation or Refinancing?`}</h3>
        <p>{`Along with the type of loans these companies provide, we examined whether these companies consolidate or refinance loans. Many companies offer both services. Loan consolidation is perhaps more useful for students who have loans from multiple lenders, both federal and private, and are interested in bringing all loans together to make one monthly payment. Refinancing loans are ideal for students who want to lower their monthly payments or their interest rates to pay the loan off within their financial budget.`}</p>
        <h3>{`Eligibility Requirements`}</h3>
        <p>{`We also looked at the eligibility requirements for these loans. Some providers have strict requirements, such as the student's enrollment status and the type of program they are enrolled in.`}</p>
        <h3>{`Provider History`}</h3>
        <p>{`Finally, considered the history of these providers, particularly the non-profit institutions that have good reputations for helping college students.`}</p>
      </ComparisonResultColumnContentBackgroundContainer>
      <ComparisonResultGettingStartedBlock mdxType="ComparisonResultGettingStartedBlock" />
      <ComparisonPageRecommendations requestingProductCategory="student refinancing loans" mdxType="ComparisonPageRecommendations" />
      <ComparisonResultPageLearnMoreContainer mdxType="ComparisonResultPageLearnMoreContainer">
        <h3>{`Learn More`}</h3>
        <p>{`Many companies that are devoted to helping current and graduated students take back control over their financial health. When it’s time to compare loans, turn to `}<a parentName="p" {...{
            "href": "/"
          }}>{`The Comparison Company`}</a>{` to find the student loan program right for you.`}</p>
      </ComparisonResultPageLearnMoreContainer>
    </ComparisonResultColumnContent>



    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      